import {Pipe, PipeTransform} from '@angular/core'
import {isString} from '@ngneat/transloco'

@Pipe({
  name: 'bgoTruncate',
  pure: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, completeWords = false, ellipsis = '...') {
    // leave untouched if unsupported
    if (limit < 0 || !value || !isString(value)) {
      return value
    }
    // no need to truncate
    if (value.length <= limit) {
      return value
    }
    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ')
    }
    return value.substring(0, limit) + ellipsis
  }
}
