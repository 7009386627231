import {Pipe, PipeTransform} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'

@Pipe({
  name: 'bgoMonth',
  pure: true,
})
export class MonthPipe implements PipeTransform {
  constructor(private readonly translocoService: TranslocoService) {}

  transform(month: number): string {
    switch (month) {
      case 1:
        return this.translocoService.translate('month.january')
      case 2:
        return this.translocoService.translate('month.february')
      case 3:
        return this.translocoService.translate('month.march')
      case 4:
        return this.translocoService.translate('month.april')
      case 5:
        return this.translocoService.translate('month.may')
      case 6:
        return this.translocoService.translate('month.june')
      case 7:
        return this.translocoService.translate('month.july')
      case 8:
        return this.translocoService.translate('month.august')
      case 9:
        return this.translocoService.translate('month.september')
      case 10:
        return this.translocoService.translate('month.october')
      case 11:
        return this.translocoService.translate('month.november')
      case 12:
        return this.translocoService.translate('month.december')
      default:
        throw new RangeError(`Invalid month: ${month}`)
    }
  }
}
