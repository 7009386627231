import {Inject, Pipe, PipeTransform} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'

@Pipe({
  name: 'bgoAgencyName',
})
export class AgencyNamePipe implements PipeTransform {
  constructor(@Inject(TranslocoService) private translocoService: TranslocoService) {}

  transform(agencyName?: string): string {
    if (agencyName && agencyName.toLowerCase().indexOf('buildigo') >= 0) {
      return agencyName
    }
    return this.translocoService.translate('registerPro.contact.generalAgency', {name: agencyName})
  }
}
