import {Pipe, PipeTransform} from '@angular/core'
import {FormControl} from '@angular/forms'
import {FileModel} from '../../craft/files/file.model'

@Pipe({
  name: 'bgoUrlFromFormControl',
  pure: true,
})
export class UrlFromFormControl implements PipeTransform {
  transform(control: FormControl<FileModel[] | null>): string | undefined {
    if (control.value?.length) {
      return control.value.slice(-1)[0].url
    }
  }
}
