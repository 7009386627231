import {Pipe, PipeTransform} from '@angular/core'
import {AuthenticatedUser} from '../../core/auth/auth-status.service'

export type UserRole = 'admin' | 'agent' | 'pro' | 'client'

@Pipe({
  name: 'bgoPrioritizedUserRole',
  pure: true,
})
export class PrioritizedUserRolePipe implements PipeTransform {
  transform(user: AuthenticatedUser | undefined): UserRole | undefined {
    if (user?.isGlobalAdministrator) {
      return 'admin'
    }
    if (user?.agent) {
      return 'agent'
    }
    if (user?.pro) {
      return 'pro'
    }
    if (user?.client) {
      return 'client'
    }
    return undefined
  }
}
