import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoMailTo',
  pure: true,
})
export class MailToPipe implements PipeTransform {
  transform(email: string): string {
    return 'mailto:' + email
  }
}
