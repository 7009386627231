import {Pipe, PipeTransform} from '@angular/core'
import {Industry, Service, Skill} from '../../../__generated__/types'

@Pipe({
  name: 'competencyList',
})
export class CompetencyListPipe implements PipeTransform {
  transform(values: Pick<Skill, 'label'>[] | Pick<Service, 'label'>[] | Pick<Industry, 'label'>[]): unknown {
    return values.map(v => v.label).join(', ')
  }
}
