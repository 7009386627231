import {Pipe, PipeTransform} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'

@Pipe({
  name: 'translateNested',
})
export class TranslateNestedPipe implements PipeTransform {
  constructor(private readonly transloco: TranslocoService) {}
  transform(value?: string, ...args: string[]): string | undefined {
    if (!value) return
    const [baseKey] = args
    return this.transloco.translate<string>(`${baseKey}.${value}`)
  }
}
