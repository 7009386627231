import {Pipe, PipeTransform} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {TypedFormGroup} from '../../core/forms/form-controls.model'

@Pipe({
  name: 'bgoAsFormGroup',
  pure: true,
})
export class AsFormGroupPipe implements PipeTransform {
  transform(form: TypedFormGroup<unknown> | unknown): FormGroup {
    const f = form as FormGroup
    if (!f) {
      throw new RangeError('Form is empty or not a FormGroup')
    }
    return f
  }
}
