import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoFullName',
  pure: true,
})
export class FullNamePipe implements PipeTransform {
  transform(item?: {firstName?: string; lastName?: string}): string {
    if (item?.firstName && item?.lastName) {
      return `${item.firstName} ${item.lastName}`
    } else if (item?.firstName && !item?.lastName) {
      return item.firstName
    } else if (!item?.firstName && item?.lastName) {
      return item.lastName
    } else {
      return ''
    }
  }
}
