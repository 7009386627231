import {Pipe, PipeTransform} from '@angular/core'
import {AbstractControl, FormControl} from '@angular/forms'
import {TypedFormControl} from '../../core/forms/form-controls.model'

@Pipe({
  name: 'bgoAsFormControl',
  pure: true,
})
export class AsFormControlPipe implements PipeTransform {
  transform(control: AbstractControl | TypedFormControl<unknown> | undefined | null): FormControl {
    const c = control as FormControl
    if (!c) {
      throw new RangeError('Control is empty or not a FormControl')
    }
    return c
  }
}
