import {Inject, Pipe, PipeTransform} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'

@Pipe({
  name: 'bgoSalutation',
  pure: true,
})
export class SalutationPipe implements PipeTransform {
  constructor(@Inject(TranslocoService) private translocoService: TranslocoService) {}

  transform(client: {salutation: Salutation}, translateKey: string): string {
    if (client.salutation === 'NONE') {
      return ''
    }
    return this.translocoService.translate(`${translateKey}.${client.salutation}`)
  }
}

type Salutation = 'MADAM' | 'NONE' | 'SIR'
