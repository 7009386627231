export function nullToUndefined<T>(value: T | null): T | undefined {
  if (value === null) {
    return undefined
  } else {
    return value
  }
}

export function undefinedToNull<T>(value: T | undefined): T | null {
  if (value === undefined) return null
  else return value
}

export const isNotNullish = <T>(value: T | null | undefined): value is T => value !== undefined && value !== null
