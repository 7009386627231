import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoFullLocation',
  pure: true,
})
export class FullLocationPipe implements PipeTransform {
  transform(
    item?: {streetAndHouseNumber?: string; locality: string; postalCode: number},
    showStreetAddress = false,
  ): string | undefined {
    if (!item) return

    if (showStreetAddress) {
      return `${item.postalCode} ${item.locality}${item.streetAndHouseNumber ? ', ' + item.streetAndHouseNumber : ''}`
    }

    return `${item.postalCode} ${item.locality}`
  }
}
