import {Validators} from '@angular/forms'

export class MoneyService {
  //Pattern for whole numbers i.e. amounts without fractions (integers)
  static readonly chfWholeAmountPattern = /^\d+(,\d{3})*('\d{3})*(\s\d{3})*(’\d{3})*$/
  //Pattern for amounts with fractions
  static readonly chfAmountPattern = /^\d+(,\d{3})*('\d{3})*(\s\d{3})*(’\d{3})*(\.[0-9]{1,2})?$/
  static readonly optionalChfAmountPattern = /^(\d+(,\d{3})*('\d{3})*(\s\d{3})*(’\d{3})*(\.[0-9]{1,2})?)?$/

  static chfWholeAmountValidator = Validators.pattern(MoneyService.chfWholeAmountPattern)
  static chfAmountValidator = Validators.pattern(MoneyService.chfAmountPattern)
  static optionalChfAmountValidator = Validators.pattern(MoneyService.optionalChfAmountPattern)

  static sanitizeChfAmount(input: string): number {
    //prettier-ignore
    const value = input?.replace(/,/g, '')
      .replace(/'/g, '')
      .replace(/’/g, '')
      .replace(/\s/g, '')
      .trim() ?? 0

    return Number(value)
  }

  static chfAmountStrToRappen(input: string): number {
    const value = this.sanitizeChfAmount(input)

    return this.chfAmountToRappen(value)
  }

  static chfAmountToRappen(value: number): number {
    return Math.round(value * 100)
  }

  static rappenToChfAmountStr(input?: number): string | undefined {
    if (!input) return undefined

    return this.rappenToChfAmount(input)?.toFixed(2)
  }

  static rappenToChfAmount(input?: number): number | undefined {
    if (!input) return input

    return input / 100
  }
}
