import {Pipe, PipeTransform} from '@angular/core'
import {MoneyService} from '../../core/forms/money.service'

@Pipe({
  name: 'bgoRappenToFranc',
  pure: true,
})
export class RappenToFrancPipe implements PipeTransform {
  transform(rappenAmount: number | string | undefined): number | string | undefined {
    if (!rappenAmount) return rappenAmount

    const amount = Number(rappenAmount)

    return MoneyService.rappenToChfAmount(amount)
  }
}
