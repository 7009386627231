import {Pipe, PipeTransform} from '@angular/core'
import {isAbsoluteUrl} from '../../core/utils/url'

@Pipe({
  name: 'bgoAbsoluteUrl',
  pure: true,
})
export class AbsoluteUrlPipe implements PipeTransform {
  transform(url: string): string {
    if (isAbsoluteUrl(url)) {
      return url
    } else {
      return `https://${url}`
    }
  }
}
