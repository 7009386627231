import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoTel',
  pure: true,
})
export class TelPipe implements PipeTransform {
  transform(telephoneNumber: string): string {
    return 'tel:' + telephoneNumber
  }
}
