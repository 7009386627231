import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoSwissPhoneNumber',
  pure: true,
})
export class SwissPhoneNumberPipe implements PipeTransform {
  transform(number: string): string {
    return `+41 ${number.replace('+41', '')}`
  }
}
